import { useEffect, useState } from "react";
import { questionsPageStyles } from "./pagesStyles";
import { Box, Typography } from "@mui/material";
import { getQuestions } from "../apiHelper";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import QuestionLibraryLayout from "../hrDashboardComponents/QuestionLibraryLayout";
import QuestionSettingsLayout from "../hrDashboardComponents/QuestionSettingsLayout";
import { useAuth0 } from "@auth0/auth0-react";
import { useApiService } from "../apiHelper";
function Questions() {
  const classes = questionsPageStyles();
  const { user } = useAuth0();
  const [questionType, setQuestionType] = useState("set");
  const [questions, setQuestions] = useState([]);
  const [datedQuestions, setDatedQuestions] = useState([]);
  const [datedQuestionsAdded, setDatedQuestionsAdded] = useState(false);
  const [settings, setSettings] = useState([]);

  const [loading, setLoading] = useState(true);

  const [tab, setTab] = useState("library");
  const handleChange = (e) => {
    setQuestionType(e.target.value);
  };
  const api = useApiService();
  useEffect(() => {
    api
      .getQuestions({ externalId: user.sub })
      .then((q) => {
        setQuestions(q.questions);
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
      });

    api
      .getDatedQuestions({ externalId: user.sub, currentTime: new Date() })
      .then((q) => {
        setDatedQuestions(q.questions);
      });
    api.getSettings().then((s) => {
      setSettings(s);
    });
  }, [tab]);
  useEffect(() => {
    api
      .getDatedQuestions({ externalId: user.sub, currentTime: new Date() })
      .then((q) => {
        setDatedQuestions(q.questions);
      });
  }, [tab, datedQuestionsAdded]);

  const updateQuestions = (newQuestions) => {
    setQuestions(newQuestions);
  };

  const triggerChangeSettings = (settingsData) => {
    api
      .updateSettings({
        ...settingsData,
        _id:
          settings.data && settings.data.length > 0 ? settings.data[0]._id : "",
      })
      .then((result) => {
        setSettings((prev) => {
          return { data: [result] };
        });
      });
  };
  return (
    <Box className={classes.outerContainer}>
      <Typography variant="h1">Questions</Typography>
      <Box className={classes.questionListOuterContainer}>
        <Box className={classes.questionListInnerContainer}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs value={tab} onChange={(e, v) => setTab(v)}>
              {/* <Tab value="myTeam" label="My Team" /> */}
              <Tab value="library" label="Library" />
              <Tab value="settings" label="Settings" />
            </Tabs>
          </Box>

          <Box className={classes.questionsListContainer}>
            {/* {tab === "myTeam" ? (
              <QuestionMyTeamLayout
                questions={questions}
                updateQuestions={updateQuestions}
                getQuestions={getQuestions}
                setQuestions={setQuestions}
                handleChange={handleChange}
                loading={loading}
                triggerChangeSettings={triggerChangeSettings}
              />
            ) : null} */}
            {tab === "library" ? (
              <QuestionLibraryLayout
                questions={questions}
                updateQuestions={updateQuestions}
                getQuestions={getQuestions}
                setQuestions={setQuestions}
                handleChange={handleChange}
                loading={loading}
                triggerChangeSettings={triggerChangeSettings}
              />
            ) : null}
            {tab === "settings" ? (
              <QuestionSettingsLayout
                questions={questions}
                setSettings={triggerChangeSettings}
                updateQuestionList={updateQuestions}
                datedQuestions={datedQuestions}
                datedQuestionsAdded={datedQuestionsAdded}
                setDatedQuestionsAdded={setDatedQuestionsAdded}
                questionType={
                  settings.data && settings.data.length > 0
                    ? settings.data[0].settingQuestionType
                    : "set"
                }
                questionInterval={
                  settings.data && settings.data.length > 0
                    ? settings.data[0].questionInterval
                    : "daily"
                }
              />
            ) : null}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default Questions;
