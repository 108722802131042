import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import MessageIcon from "@mui/icons-material/Message";
import ScoreIcon from "@mui/icons-material/Score";
import QuestionComponent from "./QuestionComponent";
import { useState } from "react";
import { modalStyles, questionsPageStyles } from "../pages/pagesStyles";
import AddIcon from "@mui/icons-material/Add";
import QuestionHeading from "./QuestionHeading";
import { useAuth0 } from "@auth0/auth0-react";
import { useApiService } from "../apiHelper";

const QuestionLibraryLayout = (props) => {
  const classes = questionsPageStyles();
  const modalClasses = modalStyles();
  const {
    questions,
    updateQuestions,
    setQuestions,
    getQuestions,
    handleChange,
    triggerChangeSettings,
    loading,
  } = props;

  const [modalInfo, setModalInfo] = useState({
    settingQuestionType: "rating",
    question: "",
    questionInterval: "daily",
  });
  const [addModalOpen, openModal] = useState(false);
  const api = useApiService();
  const { user } = useAuth0();
  return (
    <>
      <Box className={classes.questionSettingsRow}>
        <Box
          className={classes.addQuestionButton}
          onClick={() => openModal(true)}
        >
          <AddIcon color="primary" />
          <Typography variant="h2">Add</Typography>
        </Box>
      </Box>
      {!loading && questions.length > 0 && (
        <QuestionHeading
          handleChange={handleChange}
          setSettings={triggerChangeSettings}
        />
      )}
      <Box className={classes.questionListContainer}>
        {!loading && questions.length > 0 ? (
          questions.map((question) => (
            <QuestionComponent
              key={`${question._id}-${question.day}`}
              id={question._id}
              questionText={question.question}
              questionType={question.type}
              active={question.active}
              day={question.day}
              updateQuestionList={updateQuestions}
            />
          ))
        ) : (
          <Box className={classes.noQuestionsContainer}>
            <Typography variant="body1">No questions yet...</Typography>
          </Box>
        )}
        {loading && <CircularProgress />}
      </Box>
      <Modal open={addModalOpen} onClose={() => openModal(false)}>
        <Box className={modalClasses.modalBase}>
          <Typography variant="h1">New Question</Typography>
          <Box className={modalClasses.typeSelector}>
            <Select
              variant="standard"
              onChange={(e) =>
                setModalInfo({
                  ...modalInfo,
                  settingQuestionType: e.target.value,
                })
              }
              value={modalInfo.settingQuestionType}
              disableUnderline={true}
            >
              <MenuItem value="rating">
                {" "}
                <Box className={modalClasses.typeItemContainer}>
                  <Avatar classes={{ root: modalClasses.iconContainerAvatar }}>
                    <ScoreIcon color="white" fontSize="small" />
                  </Avatar>
                  Rating
                </Box>
              </MenuItem>
              <MenuItem value="comments">
                <Box className={modalClasses.typeItemContainer}>
                  <Avatar classes={{ root: modalClasses.iconContainerAvatar }}>
                    <MessageIcon color="white" fontSize="small" />
                  </Avatar>
                  Comments
                </Box>
              </MenuItem>
            </Select>
          </Box>
          <Box className={modalClasses.typeSelector}>
            <TextField
              hiddenLabel
              fullWidth
              multiline
              rows={4}
              placeholder="Type your question here..."
              variant="filled"
              onChange={(e) => {
                setModalInfo({ ...modalInfo, question: e.target.value });
              }}
            />
          </Box>
          <Box className={modalClasses.buttonRow}>
            <Button onClick={() => openModal(false)}>Cancel</Button>
            <Button
              variant="contained"
              onClick={() => {
                api.addQuestion(modalInfo, user.sub).then(() => {
                  getQuestions({ externalId: user.sub }).then((q) => {
                    setQuestions(q.questions);
                  });
                  openModal(false);
                  setModalInfo({ settingQuestionType: "rating", question: "" });
                });
              }}
            >
              Add
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default QuestionLibraryLayout;
