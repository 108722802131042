import { makeStyles } from "@mui/styles";

export const scoreCardStyles = makeStyles({
  backdrop: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#c1d7bc",
    height: "100vh",
  },
  outerContainer: {
    borderRadius: "2rem",
    minWidth: "80vw",
    backgroundColor: "white",
    display: "flex",
    flexDirection: "column",
    padding: "2rem",
  },
  loadingContainer: {
    display: "flex",
    justifyContent: "center",
  },
  welcomeMessageContainer: {
    display: "flex",
    justifyContent: "left",
    alignItems: "center",
    padding: "3rem",
    paddingTop: "3.2rem",
    paddingBottom: "7.2rem",

    "& .MuiAvatar-root": {
      height: "4rem",
      width: "4rem",
    },

    "& .MuiTypography-h1": {
      fontFamily: "Libre Baskerville",
      fontSize: "2rem",
      padding: "0.8rem",
    },
  },
  pointSelectorWrapper: {
    padding: "1.5rem 3rem",
    "& .MuiTypography-body1": {
      margin: "0 auto 4rem",
      fontSize: "1.8rem",
      fontWeight: 300,
    },
  },
  buttonContainer: {
    margin: "1.5rem 3rem",
    display: "flex",
    justifyContent: "space-between",
    display: "flex",
    alignItems: "center",
    "& .MuiTypography-body1": {
      //   display: "flex",
      //   alignItems: "center"
    },
  },
  buttonContainerRight: {
    display: "flex",
    alignItems: "center",
  },

  navigateIcon: {
    display: "flex",
    alignItems: "center",
  },
  helpSubmitContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: "1rem",
  },
});

export const helpStyles = makeStyles({
  backdrop: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  helpPageOuter: {
    backgroundColor: "white",
    padding: "2rem",
    margin: "2rem",
    borderRadius: "2rem",
    display: "flex",
    flexDirection: "column",
    width: "80vw",
    "& .MuiTypography-h1": {
      fontFamily: "Libre Baskerville",
      fontSize: "2rem",
      padding: "0.8rem",
    },
  },
  helpPageTop: {
    padding: "3rem",
  },
  helpPageBookingContainer: {},
  helpPageInner: {
    margin: "1rem",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
});

export const hrOverviewStyles = makeStyles({
  outletContainer: {
    overflow: "auto",
    width: "100%",
    display: "flex",
    backgroundColor: "#fafafa",
  },
  hrOverviewContainerOuter: {
    display: "flex",
    height: "100vh",
    backgroundColor: "#fcfcfc",
  },
  navBar: {
    width: "15vw",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  hrOverviewContainer: {
    padding: "2rem",
    display: "flex",
    // justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    width: "100%",
    "& .MuiTypography-h1": {
      fontFamily: "Libre Baskerville",
      fontSize: "2rem",
      padding: "0.8rem",
    },
    "& .MuiTypography-h2": {
      margin: 0,
      fontSize: "1.4rem",
      fontWeight: 400,
      marginBottom: "1rem",
    },
    "& .MuiTypography-h4": {
      margin: 0,
      fontSize: "1rem",
      fontWeight: 480,
      textTransform: "uppercase",
      color: "#494949",
    },
  },
  boxInformation: {
    display: "flex",
    flexDirection: "column",
  },
  informationOuter: {
    display: "flex",
    alignItems: "center",
  },
  headingRow: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
  },
  legendLine: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  legendBubble: {
    backgroundColor: "#A7C4A1",
    margin: "0.4rem",
    width: "1rem",
    height: "1rem",
    borderRadius: "50%",
  },
  content: {
    width: "1280px",
    "@media (max-width: 1410px)": {
      width: "1016px",
    },
  },
  hrRow: {
    display: "flex",
    maxWidth: 1250,
    minWidth: "85%",
    justifyContent: "space-between",
    marginTop: "1.2rem",
    marginBottom: "1.2rem",
    "@media (max-width: 1410px)": {
      width: "95%",
    },
  },
  underConstructionContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  hrRowOuter: {
    display: "flex",
    // width: 1700,
    width: "100%",
    "@media (max-width: 1920px)": {
      // width: 1280,
    },
    "@media (max-width: 1500px)": {
      // width: 950,
    },
    justifyContent: "center",
    // alignItems: "center",
  },
  dataRangeSelector: {
    "& .MuiTypography-h2": {
      margin: 0,
      fontSize: "1.2rem",
      fontWeight: 400,
    },
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  arrowContainer: {
    padding: "1rem",
    cursor: "pointer",
  },
  hrBarRight: {
    display: "flex",
    alignItems: "center",
  },
  "@media (max-width: 1410px)": {
    thirdRowLeft: {
      marginLeft: "1rem",
    },
  },
});
export const questionsPageStyles = makeStyles({
  outerContainer: {
    width: "100%",
    padding: "2rem",
    display: "flex",
    // justifyContent: "center",
    alignItems: "flex-start",
    flexDirection: "column",

    "& .MuiTypography-h1": {
      fontFamily: "Libre Baskerville",
      fontSize: "2rem",
      padding: "0.8rem",
    },
    "& .MuiTypography-h2": {
      margin: 0,
      fontSize: "1.4rem",
      fontWeight: 400,
      marginBottom: "1rem",
    },
    "& .MuiTypography-h4": {
      margin: 0,
      fontSize: "1rem",
      fontWeight: 480,
      textTransform: "uppercase",
      color: "#494949",
    },
  },
  questionListOuterContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
  },
  questionListInnerContainer: {
    "@media (min-width: 1100px)": {
      width: "100%",
    },
    "@media (min-width: 1400px)": {
      width: "100%",
    },
  },
  questionSettingsRow: {
    display: "flex",
    marginTop: "1rem",
    marginBottom: "1rem",
  },
  addQuestionButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 60,
    borderRadius: 40,
    border: "2px solid #174C85",
    padding: "0.4rem",
    cursor: "pointer",
    marginRight: "1rem",
    "& .MuiTypography-h2": {
      margin: 0,
      marginLeft: "0.2rem",
      marginRight: "0.4rem",
      marginTop: "0.2rem",
      fontSize: "1rem",
      fontWeight: 800,
    },
    "&:hover": {
      backgroundColor: "#174C85",
      "& .MuiTypography-h2": {
        color: "white",
      },
    },
  },
  questionListContainer: {
    overflow: "hidden",
  },
  noQuestionsContainer: {
    "& .MuiTypography-body1": {
      margin: 0,
      marginLeft: "0.2rem",
      marginRight: "0.4rem",
      marginTop: "0.2rem",
      fontSize: "1rem",
      color: "grey",
      fontWeight: 400,
    },
  },
});

export const questionsSettingsLayoutStyles = makeStyles({
  outerContainer: {
    width: "100%",
  },
  selectContainer: {
    display: "flex",
    justifyContent: "flex-start",
    backgroundColor: "#fafafa",
    borderRadius: "0.8rem",
    paddingRight: "0.4rem",
  },
  selectContainerOuter: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "white",
    padding: "0.8rem",
    paddingLeft: "1rem",
    paddingRight: "1rem",
    marginTop: "1rem",
    "& .MuiTypography-h2": {
      margin: 0,
      marginTop: "0.2rem",
      fontSize: "1.1rem",
      fontWeight: 400,
    },
  },
  iconContainerAvatar: {
    height: "2rem !important",
    width: "2rem !important",
    backgroundColor: "#A7C4A1 !important",
    marginRight: "0.4rem",
  },
  selectOptionInner: {
    padding: "0.6rem",
    paddingTop: "0.2rem",
    paddingBottom: "0.2rem",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    "& .MuiTypography-body1": {
      margin: "0",
      marginTop: "0.1rem",
      fontSize: "1rem",
      fontWeight: 400,
    },
  },
  sectionContainer: {
    marginTop: "2rem",
  },
  sectionHeading: {
    display: "flex",
    alignItems: "center",
    marginTop: "1rem",
    marginBottom: "0.4rem",
    "& .MuiTypography-h3": {
      margin: "0",
      fontSize: "1.4rem",
      fontWeight: 400,
      color: "#575757",
    },
  },
  sectionButtonInner: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
});
export const modalStyles = makeStyles({
  modalBase: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    backgroundColor: "white",
    padding: "1.5rem",
    borderRadius: 24,
    boxShadow: 24,
    "& .MuiTypography-h1": {
      margin: 0,
      marginLeft: "0.2rem",
      marginRight: "0.4rem",
      marginTop: "0.2rem",
      fontSize: "2rem",
      fontWeight: 400,
    },
    "& .MuiTypography-h5": {
      margin: 0,
      fontSize: "0.75rem",
      fontWeight: 400,
      textTransform: "uppercase",
      lineHeight: 2.66,
      color: "rgba(0, 0, 0, 0.6)",
    },
  },
  typeSelector: {
    margin: "1rem",
    marginLeft: 0,
    marginRight: 0,
  },
  questionSelector: {
    width: "100%",
  },
  iconContainerAvatar: {
    height: "2rem !important",
    width: "2rem !important",
    backgroundColor: "#A7C4A1 !important",
    marginRight: "0.4rem",
  },
  typeItemContainer: {
    display: "flex",
    alignItems: "center",
  },
  buttonRow: {
    display: "flex",
    justifyContent: "flex-end",
  },
});

export const peoplePageStyles = makeStyles({
  peopleLibraryOuter: {
    width: "100%",
  },
  peopleListContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
  },
  peopleListInnerContainer: {
    "@media (min-width: 1100px)": {
      width: "100%",
    },
    "@media (min-width: 1400px)": {
      width: "100%",
    },
  },
  outerContainer: {
    padding: "2rem",
    display: "flex",
    // justifyContent: "center",
    alignItems: "flex-start",
    flexDirection: "column",
    width: "100%",
    "& .MuiTypography-h1": {
      fontFamily: "Libre Baskerville",
      fontSize: "2rem",
      padding: "0.8rem",
    },
    "& .MuiTypography-h2": {
      margin: 0,
      fontSize: "1.4rem",
      fontWeight: 400,
      marginBottom: "1rem",
    },
    "& .MuiTypography-h4": {
      margin: 0,
      fontSize: "1rem",
      fontWeight: 480,
      textTransform: "uppercase",
      color: "#494949",
    },
  },
  settingsRow: {
    display: "flex",
    marginTop: "1rem",
    marginBottom: "1rem",
  },
  addQuestionButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 60,
    borderRadius: 40,
    border: "2px solid #174C85",
    padding: "0.4rem",
    cursor: "pointer",
    marginRight: "1rem",
    "& .MuiTypography-h2": {
      margin: 0,
      marginLeft: "0.2rem",
      marginRight: "0.4rem",
      marginTop: "0.2rem",
      fontSize: "1rem",
      fontWeight: 800,
    },
    "&:hover": {
      backgroundColor: "#174C85",
      "& .MuiTypography-h2": {
        color: "white",
      },
    },
  },
});
export const bookingsPageStyles = makeStyles({
  outerContainer: {
    padding: "2rem",
    display: "flex",
    // justifyContent: "center",
    alignItems: "flex-start",
    flexDirection: "column",
    width: "100%",
    "& .MuiTypography-h1": {
      fontFamily: "Libre Baskerville",
      fontSize: "2rem",
      padding: "0.8rem",
    },
    "& .MuiTypography-h2": {
      margin: 0,
      fontSize: "1.4rem",
      fontWeight: 400,
      marginBottom: "1rem",
    },
  },
});

export const personPageStyles = makeStyles({
  outerContainer: {
    padding: "2rem",
    display: "flex",
    // justifyContent: "center",
    alignItems: "flex-start",
    flexDirection: "column",
    width: "100%",
    "& .MuiTypography-h1": {
      fontFamily: "Libre Baskerville",
      fontSize: "2rem",
      paddingTop: "0.8rem",
      paddingBottom: "0.8rem",
    },
    "& .MuiTypography-h2": {
      margin: 0,
      fontSize: "1.4rem",
      fontWeight: 400,
      marginBottom: "1rem",
    },
    "& .MuiTypography-h4": {
      margin: 0,
      fontSize: "1rem",
      fontWeight: 480,
      textTransform: "uppercase",
      color: "#494949",
    },
  },
  headingBar: {},
  subHeadingBar: {
    marginTop: 10,
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
  },
  answersContainer: {
    display: "flex",
    justifyItems: "space-between",
    width: "100%",
  },
  personalRow: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
  },
});

export const successPageStyles = makeStyles({
  backdrop: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#c1d7bc",
    height: "100vh",
    "& .MuiTypography-h1": {
      fontFamily: "Libre Baskerville",
      fontSize: "2rem",
      padding: "0.8rem",
    },
    "& .MuiTypography-body1": {
      fontSize: "1.2rem",
      fontWeight: 300,
      textTransform: "none !important",
    },
  },
  outerContainer: {
    borderRadius: "2rem",
    minWidth: "80vw",
    backgroundColor: "white",
    display: "flex",
    flexDirection: "column",
    padding: "2rem",
    textAlign: "center",
  },
  somethingElseContainer: {
    borderTop: "1px solid #A7C4A1",
    paddingTop: "1.2rem",
    marginTop: "1.2rem",
  },
});

export const commentSuccessPageStyles = makeStyles({
  backdrop: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#c1d7bc",
    height: "100vh",
    "& .MuiTypography-h1": {
      fontFamily: "Libre Baskerville",
      fontSize: "2rem",
      padding: "0.8rem",
    },
  },
  outerContainer: {
    borderRadius: "2rem",
    minWidth: "80vw",
    backgroundColor: "white",
    display: "flex",
    flexDirection: "column",
    padding: "2rem",
    textAlign: "left",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end",
    marginRight: "2rem",
    marginTop: "1rem",
  },
});
export const somethingElsePageStyles = makeStyles({
  backdrop: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#c1d7bc",
    height: "100vh",
    "& .MuiTypography-h1": {
      fontFamily: "Libre Baskerville",
      fontSize: "2rem",
      padding: "0.8rem",
    },
  },
  outerContainer: {
    borderRadius: "2rem",
    minWidth: "80vw",
    backgroundColor: "white",
    display: "flex",
    flexDirection: "column",
    padding: "2rem",
  },
  titleContainer: {
    display: "flex",
    paddingBottom: "1rem",
  },
  buttonContainer: {
    marginTop: "1rem",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& .MuiTypography-body2": {
      textTransform: "none !important",
      margin: 0,
      fontWeight: 400,
      fontSize: "1rem",
      lineHeight: 1.5,
    },
  },
  buttonContainerRight: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
});

export const newPersonModalStyles = makeStyles({
  modalBase: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    backgroundColor: "white",
    padding: "1.5rem",
    borderRadius: 24,
    boxShadow: 24,
    "& .MuiTypography-h1": {
      margin: 0,
      marginLeft: "0.2rem",
      marginRight: "0.4rem",
      marginTop: "0.2rem",
      fontSize: "2rem",
      fontWeight: 400,
    },
    "& .MuiTypography-h4": {
      margin: 0,
      fontSize: "1rem",
      fontWeight: 400,
      color: "#cb5050",
    },
  },
  typeSelector: {
    margin: "0.5rem",
  },
  iconContainerAvatar: {
    height: "2rem !important",
    width: "2rem !important",
    backgroundColor: "#A7C4A1 !important",
    marginRight: "0.4rem",
  },
  typeItemContainer: {
    display: "flex",
    alignItems: "center",
  },
  buttonRow: {
    marginTop: "1rem",
    marginBottom: 0,
    margin: "0.4rem",
    display: "flex",
    justifyContent: "flex-end",
  },
  formRow: {
    display: "flex",
    flexDirection: "row",
  },
});
