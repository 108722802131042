import axios from "axios";
import { createContext, useContext, useCallback, useMemo } from "react";
import { useAuth0 } from "@auth0/auth0-react";

const apiUrl = process.env.REACT_APP_API_URL || "http://localhost:9090/";

// Create an Auth context for the API
const ApiAuthContext = createContext(null);

// Provider component that wraps your app and makes auth object available to any
// child component that calls useApi().
export const ApiProvider = ({ children }) => {
  const { getAccessTokenSilently } = useAuth0();

  // Function to get a token
  const getToken = useCallback(async () => {
    try {
      return await getAccessTokenSilently();
    } catch (error) {
      console.error("Error getting access token:", error);
      return null;
    }
  }, [getAccessTokenSilently]);

  return (
    <ApiAuthContext.Provider value={{ getToken }}>
      {children}
    </ApiAuthContext.Provider>
  );
};

// Hook for components to get the auth object
export const useApi = () => {
  const context = useContext(ApiAuthContext);
  if (context === null) {
    throw new Error("useApi must be used within an ApiProvider");
  }
  return context;
};

// Core request function that can be used directly
const apiRequest = async (method, endpoint, options = {}) => {
  const {
    token,
    params,
    data,
    additionalHeaders = {},
    requiresAuth = true,
  } = options;

  // Build the URL
  const url = `${apiUrl}${endpoint}`;

  // Build the config
  const config = {};

  // Add headers if token is provided
  if (token) {
    config.headers = {
      Authorization: `Bearer ${token}`,
      ...additionalHeaders,
    };
  } else if (Object.keys(additionalHeaders).length > 0) {
    config.headers = { ...additionalHeaders };
  }

  // Add params if provided
  if (params) {
    config.params = params;
  }

  // Make the request based on the method
  let response;
  switch (method.toLowerCase()) {
    case "get":
      response = await axios.get(url, config);
      break;
    case "post":
      response = await axios.post(url, data, config);
      break;
    case "put":
      response = await axios.put(url, data, config);
      break;
    case "delete":
      response = await axios.delete(url, config);
      break;
    default:
      throw new Error(`Unsupported HTTP method: ${method}`);
  }

  return response.data;
};

// Helper functions for specific HTTP methods that don't require token
export const get = (endpoint, options = {}) =>
  apiRequest("get", endpoint, options);
export const post = (endpoint, options = {}) =>
  apiRequest("post", endpoint, options);
export const put = (endpoint, options = {}) =>
  apiRequest("put", endpoint, options);
export const del = (endpoint, options = {}) =>
  apiRequest("delete", endpoint, options);

// Convert to a custom hook that can use React hooks
export const useApiService = () => {
  // Get the token getter function from our context
  const { getToken } = useApi();

  // Define methods with automatic token retrieval
  const withAuth = useCallback(
    {
      get: async (endpoint, options = {}) => {
        const token = await getToken();
        console.log("happened");
        return apiRequest("get", endpoint, { ...options, token });
      },
      post: async (endpoint, options = {}) => {
        const token = await getToken();
        return apiRequest("post", endpoint, { ...options, token });
      },
      put: async (endpoint, options = {}) => {
        const token = await getToken();
        return apiRequest("put", endpoint, { ...options, token });
      },
      delete: async (endpoint, options = {}) => {
        const token = await getToken();
        return apiRequest("delete", endpoint, { ...options, token });
      },
    },
    [getToken]
  );

  // Memoize the API service to maintain a stable reference
  return useMemo(
    () => ({
      // API functions that automatically fetch token
      getDashboard: (data) => withAuth.get("dashboard", { params: data }),
      getMoreLatestResponses: (data) =>
        withAuth.get("dashboard/latest-responses", { params: data }),
      getComments: (data) => withAuth.get("comments", { params: data }),
      getMyAnswers: (data) => withAuth.get("answer", { params: data }),
      getPersonDetails: (data) =>
        withAuth.get("people/details", { params: data }),
      getGraphData: (data) => withAuth.get("graph-data", { params: data }),
      getPeople: (data, role) =>
        withAuth.get("people", { params: { ...data, requester: role } }),
      getAllPeople: () => withAuth.get("people/all"),
      getManagers: (data) => withAuth.get("people/managers", { params: data }),
      getProfile: (data) => withAuth.get("people/profile", { params: data }),
      getUserPermissions: (data) =>
        withAuth.get("people/user-permissions", { params: data }),
      getAvailableTimes: (data) =>
        withAuth.get("people/available-times", { params: data }),
      getBookings: (data) => withAuth.get("bookings", { params: data }),
      getAvailableTimesClient: (data) =>
        withAuth.get("people/available-times-client", { params: data }),
      getAllBookableTimes: (data) =>
        withAuth.get("people/all-users-available-times", { params: data }),
      getAllBookableTimesBulk: (data) =>
        withAuth.get("people/clustered-available-times", { params: data }),
      getManagedByOptions: (data, role) =>
        withAuth.get("people/managed-by-options", {
          params: { ...data, requester: role },
        }),
      getSettings: (data) => withAuth.get("settings", { params: data }),
      getCompanyName: (data) =>
        withAuth.get("settings/company-name", { params: data }),
      getQuestionDetails: (data) =>
        withAuth.get("questions/get-one", {
          params: {
            questionId: data.questionId,
            dayOfWeek: data.dayOfWeek,
            date: data.date,
            requestingUserId: data.requestingUserId,
          },
        }),
      getAnsweredOrNot: (data) =>
        withAuth.get("answer/answered-today", { params: data }),
      updateQuestion: (questionId, data) =>
        withAuth.put("questions/update-one", {
          data: {
            questionId,
            question: data.question,
            type: data.type,
            active: data.active,
            day: data.day,
            requestingUserId: data.requestingUserId,
          },
        }),
      updateRescheduleBooking: (bookingId, data) =>
        withAuth.put("bookings/reschedule", {
          data: {
            ...data,
            bookingId,
          },
        }),
      updateCancelBooking: (bookingId, data) =>
        withAuth.put("bookings/cancel", {
          data: {
            ...data,
            bookingId,
          },
        }),
      updateAvailableData: (externalId, data) =>
        withAuth.put("people/available-times", {
          data: {
            externalId,
            availableTimes: data,
          },
        }),
      updatePerson: (data) => withAuth.put("people/update", { data }),
      deactivateUser: (data) => withAuth.put("people/deactivate", { data }),
      updateSettings: (data) =>
        withAuth.put("settings", {
          data: {
            ...data,
          },
        }),
      addQuestion: (data, requestingUserId) =>
        withAuth.post("questions", {
          data: {
            type: data.type,
            question: data.question,
            requestingUserId,
          },
        }),
      addAnswer: (data) => withAuth.post("answer", { data }),
      addComments: (data) => withAuth.post("comments", { data }),
      addBooking: (data) => withAuth.post("bookings", { data }),
      addDatedQuestion: (data) =>
        withAuth.put("questions/set-question-date", { data }),
      removeQuestionDate: (data) =>
        withAuth.put("questions/remove-question-date", { data }),
      confirmBooking: (data) => withAuth.put("bookings/confirm", { data }),
      addPerson: (data) => withAuth.post("people", { data }),
      getDatedQuestions: (data) =>
        withAuth.get("questions/get-dated-questions", { params: data }),

      // Public API functions (no auth required)
      getQuestions: (data) => get("questions", { params: data }),
      getRandomQuestion: (data) =>
        get("questions/get-random-question", { params: data }),
      searchQuestions: (data) => get("questions/search", { params: data }),
      getTenantData: (data) => get("tenants", { params: data }),
    }),
    [withAuth]
  );
};

// For backward compatibility - these functions still require explicitly passing tokens
// They can remain in use until code is migrated to the new pattern
export const getDashboard = (token, data) =>
  get("dashboard", { token, params: data });

export const getMoreLatestResponses = (token, data) =>
  get("dashboard/latest-responses", { token, params: data });

export const getQuestions = (data) => get("questions", { params: data });

export const getRandomQuestion = (data) =>
  get("questions/get-random-question", { params: data });

export const searchQuestions = (data) =>
  get("questions/search", { params: data });

export const getComments = (token, data) =>
  get("comments", { token, params: data });

export const getMyAnswers = (token, data) =>
  get("answer", { token, params: data });

export const getPersonDetails = (token, data) =>
  get("people/details", { token, params: data });

export const getTenantData = (data) => get("tenants", { params: data });

export const getGraphData = (token, data) =>
  get("graph-data", { token, params: data });

export const getPeople = (token, data, role) =>
  get("people", { token, params: { ...data, requester: role } });

export const getAllPeople = (token) => get("people/all", { token });

export const getManagedByOptions = (token, data, role) =>
  get("people/managed-by-options", {
    token,
    params: { ...data, requester: role },
  });

export const getManagers = (token, data) =>
  get("people/managers", { token, params: data });

export const getProfile = (token, data) =>
  get("people/profile", { token, params: data });

export const getUserPermissions = (token, data) =>
  get("people/user-permissions", { token, params: data });

export const getAvailableTimes = (token, data) =>
  get("people/available-times", { token, params: data });

export const getBookings = (token, data) =>
  get("bookings", { token, params: data });

export const getDatedQuestions = (data) =>
  get("questions/get-dated-questions", { params: data });

export const getAvailableTimesClient = (token, data) =>
  get("people/available-times-client", { token, params: data });

export const getAllBookableTimes = (token, data) =>
  get("people/all-users-available-times", { token, params: data });

export const getAllBookableTimesBulk = (token, data) =>
  get("people/clustered-available-times", { token, params: data });

export const getSettings = (token, data) =>
  get("settings", { token, params: data });

export const getCompanyName = (token, data) =>
  get("settings/company-name", { token, params: data });

export const getQuestionDetails = (token, data) =>
  get("questions/get-one", {
    token,
    params: {
      questionId: data.questionId,
      dayOfWeek: data.dayOfWeek,
      date: data.date,
      requestingUserId: data.requestingUserId,
    },
  });

export const getAnsweredOrNot = (token, data) =>
  get("answer/answered-today", { token, params: data });

export const updateQuestion = (token, questionId, data) =>
  put("questions/update-one", {
    data: {
      questionId,
      question: data.question,
      type: data.type,
      active: data.active,
      day: data.day,
      requestingUserId: data.requestingUserId,
    },
    // Note: The original code doesn't use token authentication here
  });

export const updateRescheduleBooking = (token, bookingId, data) =>
  put("bookings/reschedule", {
    token,
    data: {
      ...data,
      bookingId,
    },
  });

export const updateCancelBooking = (token, bookingId, data) =>
  put("bookings/cancel", {
    token,
    data: {
      ...data,
      bookingId,
    },
  });

export const updateAvailableData = (token, externalId, data) =>
  put("people/available-times", {
    token,
    data: {
      externalId,
      availableTimes: data,
    },
  });

export const updatePerson = (token, data) =>
  put("people/update", { token, data });

export const deactivateUser = (token, data) =>
  put("people/deactivate", { token, data });

export const updateSettings = (token, id, data) =>
  put("settings", {
    token,
    data: {
      id,
      ...data,
    },
  });

export const addQuestion = (token, data, requestingUserId) =>
  post("questions", {
    token,
    data: {
      type: data.type,
      question: data.question,
      requestingUserId,
    },
  });

export const addAnswer = (token, data) => post("answer", { token, data });

export const addComments = (token, data) => post("comments", { token, data });

export const addBooking = (token, data) => post("bookings", { token, data });

export const addDatedQuestion = (token, data) =>
  put("questions/set-question-date", { token, data });

export const removeQuestionDate = (token, data) =>
  put("questions/remove-question-date", { token, data });

export const confirmBooking = (token, data) =>
  put("bookings/confirm", { token, data });

export const addPerson = (token, data) => post("people", { token, data });
